import { LoadingButton } from '@mui/lab'
import { Button, Box, Stack } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FieldType } from 'types/employees'

const createData = (name: string, label: string, value: string) => {
  return { name, label, value }
}

const getFields = (fields: Array<FieldType>) => {
  return fields.map(field => {
    return createData(field.name, field.label, field.value)
  })
}

export default function FieldsTable({
  fields,
  onClose,
  clearFields
}: {
  fields: Array<FieldType>
  onClose: () => void
  clearFields: () => void
}) {
  const rows = getFields(fields)
  return (
    <Box>
      <TableContainer component={Paper} sx={{ width: 'auto' }}>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Value</TableCell>
              <TableCell align="right">Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
                <TableCell align="right">{row.label}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" justifyContent="flex-end" paddingTop={2} gap={2}>
        <Button
          children="Close"
          variant="text"
          size="large"
          onClick={onClose}
          sx={{ fontSize: 16, width: 237, height: 42 }}
        />
        <LoadingButton
          size="large"
          children="Try again"
          variant="contained"
          onClick={clearFields}
          sx={{ fontSize: 16, width: 237, height: 42 }}
        />
      </Stack>
    </Box>
  )
}
