import React, {
  createContext,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import axiosInstance from 'api/axiosMiddleware'
import useNavigation from 'hooks/useNavigation'
import store from 'storejs'

import { ContextProps, UserRole } from 'types/auth'
import type { User } from 'types/auth'
import { getCompanyId, getUserRole } from 'utils'

export const AuthContext = createContext<ContextProps>({
  login: () => null,
  loginAsCompany: () => null,
  onUpdateUser: () => null,
  logout: () => null,
  setCommonHeaders: () => null,
  session: {} as User,
  user: {} as User['user'],
  userRole: '' as UserRole
})

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { onNavigate } = useNavigation()
  const { token, user } = getCompanyId()

  const [session, setSession] = useState<User>({
    token,
    user
  })

  const login = useCallback((session: User) => {
    store.set('SESSION', session)
    setCommonHeaders(session.token)
    setUser(session)
    onNavigate('/dashboard')
  }, [])

  const loginAsCompany = useCallback((session: User) => {
    store.set('SESSION_BY_COMPANY', session)
    setCommonHeaders(session.token)
    setUser(session)
    onNavigate('/dashboard')
  }, [])

  const logout = useCallback(() => {
    store.remove('SESSION')
    store.remove('SESSION_BY_COMPANY')
    setSession({} as User)
    window.location.reload()
    window.location.href = '/login'
  }, [])

  const setUser = (session: User) => {
    setSession({
      token: session.token,
      user: session.user
    })
  }

  const setCommonHeaders = (token: string) => {
    if (token) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
    }
  }

  const onUpdateUser = (session: User) => {
    store.set('SESSION', session)
    setUser(session)
  }

  useLayoutEffect(() => {
    setCommonHeaders(token)
  }, [])

  const userRole = getUserRole({
    type: session?.user?.type,
    role: session?.user?.role
  })

  const value = useMemo(
    () => ({
      session,
      user: session.user,
      userRole: userRole as UserRole,
      login,
      logout,
      loginAsCompany,
      onUpdateUser,
      setCommonHeaders
    }),
    [session, login, logout]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContext
