import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack, Typography } from '@mui/material'
import DragAndDropFile from 'components/dragAndDropFile'
import { Formik, Form, Field } from 'formik'
import { COLORS } from 'styles/colors'
import FieldsTable from '../fields'
import useInvitation, { validationSchema } from './useInvitation'

export default function InvitationUpload({ onClose }: { onClose: () => void }) {
  const { fields, onHandleDrop, onHandleSubmit, clearFields } =
    useInvitation(onClose)

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ csvFile: '' }}
      onSubmit={(_, actions) => onHandleSubmit(actions)}
    >
      {({ setFieldValue, touched, errors, isSubmitting }) => {
        return (
          <Box component={Form} margin="20px">
            <Typography variant="h3" marginY={4}>
              Upload a CSV file
            </Typography>
            {fields.length > 0 ? (
              <FieldsTable
                fields={fields}
                onClose={onClose}
                clearFields={clearFields}
              />
            ) : (
              <Box>
                <Box position="relative" marginBottom={4} width="100%">
                  <Field
                    name="csvFile"
                    accept={{
                      'text/csv': ['.csv']
                    }}
                    as={DragAndDropFile}
                    onDrop={(file: any) => {
                      onHandleDrop({ file })
                      setFieldValue('csvFile', 'file')
                    }}
                    boxProps={{
                      height: '310px'
                    }}
                  />
                  {touched.csvFile && errors.csvFile && (
                    <Typography
                      variant="h6"
                      color={COLORS.red1}
                      position="absolute"
                      bottom="-25px"
                    >
                      {errors.csvFile}
                    </Typography>
                  )}
                </Box>
                <Stack
                  gap={2}
                  paddingTop={2}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    children="Cancel"
                    variant="text"
                    size="large"
                    onClick={onClose}
                    sx={{ fontSize: 16, width: 237, height: 42 }}
                  />
                  <LoadingButton
                    size="large"
                    type="submit"
                    children="Send"
                    variant="contained"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    sx={{ fontSize: 16, width: 237, height: 42 }}
                  />
                </Stack>
              </Box>
            )}
          </Box>
        )
      }}
    </Formik>
  )
}
