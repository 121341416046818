import { useState } from 'react'
import { UploadEmployees } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { FormikHelpers } from 'formik'
import { useQueryClient } from 'react-query'
import { FieldType } from 'types/employees'
import * as yup from 'yup'

export const validationSchema = yup.object({
  csvFile: yup.string().required('Archivo required')
})

export default function useInvitation(callback: () => void) {
  const [csvFile, setCsvFile] = useState<File>()
  const [fields, setFields] = useState<Array<FieldType>>([])

  const { onNotify } = useNotification()
  const query = useQueryClient()

  const onHandleDrop = ({ file }: { file: File[] }) => {
    const currentFile = file[0]
    setCsvFile(currentFile)
  }

  const onHandleSubmit = (actions: FormikHelpers<{ csvFile: string }>) => {
    const formData = new FormData()
    formData.append('file', csvFile as Blob)
    UploadEmployees(formData)
      .then(({ data }) => {
        if (data.invalids && data.errors.length > 0) {
          const errors = data.errors.map((errorObj: any) => {
            const key = Object.keys(errorObj).find(k => k !== 'errors')
            return {
              name: key,
              label: errorObj.errors[0],
              value: errorObj[key as string]
            }
          })
          setFields(errors)
          return
        }
        onNotify({
          message: 'Invitations sent successfully',
          severity: 'success'
        })
        callback()
        query.invalidateQueries('EMPLOYEE')
      })
      .catch(error => {
        console.error('error', error)
      })
      .finally(() => {
        actions.setSubmitting(false)
        actions.resetForm()
      })
  }
  const clearFields = () => setFields([])

  return { fields, onHandleSubmit, clearFields, onHandleDrop }
}
