import axios, { AxiosRequestConfig } from 'axios'
import { config } from 'config'
import store from 'storejs'

// Create axios instance with default config
const axiosInstance = axios.create({
  baseURL: `${config.beegoppApi}/${config.beegoppPrefix}`,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Add request interceptor to add auth token
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // Check for company session first, then regular session
    const companySession = store.get('SESSION_BY_COMPANY')
    const regularSession = store.get('SESSION')

    const token = companySession
      ? companySession.token
      : regularSession
      ? regularSession.token
      : null

    if (token) {
      config.headers = config.headers || {}
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Add response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // Check if error is 401
    if (error.response?.status === 401) {
      // Clear both session storages
      store.remove('SESSION')
      store.remove('SESSION_BY_COMPANY')

      // Redirect to root path
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
